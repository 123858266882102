@define-mixin dropdownMenu {
    position: absolute;
    width: 100%;
    background-color: var(--whiteBaseColor);
    z-index: 2;
    outline: none;
    overflow: hidden;
}

@define-mixin dropdownBoxShadow {
    box-shadow: 0 4px 15px 5px rgb(var(--blackBaseRgb) / 11%);
}
