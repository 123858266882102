.SearchBoxResults {
    background: var(--whiteBaseColor);
    max-height: 324px;
    overflow-x: auto;

    & ul {
        @mixin clearList;

        & > li {
            border-bottom: 1px solid var(--blackLighten50LegacyColor);

            &:first-child {
                border-top: 1px solid var(--blackLighten50LegacyColor);
            }
        }
    }
}

.Message {
    padding: 24px 16px;
    display: block;
}
