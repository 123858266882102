.HomeHeroSearchEmployees {
    background: var(--blackLighten50Color);
    padding: 48px 32px;

    & .Link.Link {
        &:hover {
            & .LinkLabel {
                border-bottom-color: var(--blackLighten30Color);
            }
        }

        & .LinkLabel {
            border-bottom: 2px solid var(--blackLighten10Color);
        }
    }
}

.Employee {
    width: 100%;

    & .EmployeeDetails {
        @mixin p30;
    }

    & .EmployeeFullName {
        margin-bottom: 8px;
        font-weight: 600;
    }

    & .EmployeeFacultyDetail:not(:last-child) {
        &::after {
            content: ' | ';
        }
    }
}

.HighlightedItem {
    & .EmployeeFullName {
        color: var(--primaryHKBaseColor);
    }
}
