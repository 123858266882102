.MultiselectDropdownWithStatus {
    position: relative;
}

.ToggleButton {
    @mixin p30;

    @mixin onFocus {
        @mixin focusVisible;
    }
    position: relative;
    background-color: var(--whiteBaseColor);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 0 16px;
    z-index: 4;
}

.SelectedListText {
    @mixin ellipsisOverflow;
    margin-right: 8px;
}

.ArrowIcon {
    @mixin fixedRatioSvgIcon 24px;
}

.List {
    @mixin clearList;
    max-height: 324px;
    overflow-x: auto;
}

.StatusMessage {
    padding: 24px 16px;
    display: block;
}

.Dropdown {
    @mixin dropdownMenu;
}

.Dropdown--open {
    @mixin dropdownBoxShadow;
}

.Item {
    &.Item--highlighted {
        background-color: var(--support02HKLighten20Color);
    }
}
