.HomeHeroSearchBox {
    display: flex;
    align-items: flex-start;
    margin-bottom: 24px;
}

.SearchInput {
    lost-column: 10/12 0 0;

    @media (--tablet) {
        lost-column: 9/12 0 0;
    }
}

.SearchText {
    @media (--mobileOnly) {
        @mixin visuallyHidden;
    }
}

.LinkButton {
    z-index: 2;
    lost-column: 2/12 0 0;

    @media (--tablet) {
        lost-column: 3/12 0 0;
    }

    &.LinkButton.LinkButton {
        @mixin onFocus {
            @mixin focusVisible 0;
        }
        padding-left: 16px;
        padding-right: 16px;
    }
}

.SearchIcon {
    &:global(.SvgIcon.SvgIcon.SvgIcon):first-child {
        margin: 0;

        @media (--tablet) {
            display: none;
        }
    }
}

.SearchBoxResults {
    margin: 16px;
}
