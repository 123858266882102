.HomeHeroSearchStudies {
    background-color: var(--primaryHKBaseColor);
    padding: 48px 32px;
}

.SearchSectionTabletAndAbove {
    display: none;
    padding-bottom: 24px;

    @media (--tablet) {
        display: flex;
    }
}

.Dropdown {
    lost-column: 9/24;
}

.ResultsLink {
    lost-column: 3/12;

    &.ResultsLink {
        padding-right: 16px;
        padding-left: 16px;
    }
}

.HighlightedItem {
    & .StudyName {
        color: var(--primaryHKBaseColor);
    }
}

.LinkList {
    & > li {
        color: var(--whiteBaseColor);
    }
}

.Study {
    width: 100%;

    & .StudyDetails {
        @mixin p30;
    }

    & .StudyName {
        font-weight: 600;
        margin-bottom: 8px;
    }

    & .StudyInfo:not(:last-child)::after {
        content: ' | ';
    }
}
