.LinkList {
    @mixin clearList;
    display: flex;
    flex-wrap: wrap;
}

.LinkListElement {
    &:not(:last-child) {
        margin-right: 24px;

        @media (--tablet) {
            margin-right: 32px;
        }
    }
}

.Link {
    @mixin p20;
}
