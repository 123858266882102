.ItemIcon {
    $baselineAlignmentOffset: 2px;

    @mixin fixedRatioSvgIcon 16px, var(--blackLighten30Color);
    @mixin svgIconFillAnimation;
    top: $baselineAlignmentOffset;
}

.Item {
    padding: 8px 0;
    display: flex;
    align-items: baseline;
    gap: 8px;

    &.Item--selected {
        & .ItemIcon {
            @mixin svgIconColor var(--blackBaseColor);
        }
    }
}

.HighlightedItem {
    & .Item {
        color: var(--primaryHKBaseColor);

        & .ItemIcon {
            @mixin svgIconColor var(--blackLighten10Color);
        }

        & .ItemLabel {
            @mixin visuallyBold;
        }
    }
}

.ResultList {
    padding: 16px 0;
}

.ListItem {
    padding: 0 16px;
    cursor: pointer;

    &:first-child {
        & .Item {
            border-top: 1px solid var(--blackLighten50LegacyColor);
        }
    }

    & .Item {
        border-bottom: 1px solid var(--blackLighten50LegacyColor);
    }
}
