.HomeHero {
    @mixin heroBlockHeight;
    margin-top: calc(-1 * var(--headerMobileHeight));

    @media (--tablet) {
        position: relative;
        margin-top: calc(-1 * var(--headerHeight));
    }
}

.Hero {
    padding-bottom: 96px;

    @media (--tablet) {
        padding-bottom: 64px;
    }
}

.HeroInner {
    display: flex;
    flex-flow: column;
    gap: 64px;
}

.Info {
    color: var(--whiteBaseColor);
    padding-top: calc(var(--headerMobileHeight) + 48px);

    @media (--tablet) {
        lost-column: 7/12;
        padding-top: calc(var(--headerHeight) + 144px);
    }

    & .Heading {
        @mixin hyphenateWords manual;
        @mixin h20;
        margin-bottom: 24px;

        @media (--desktop) {
            @mixin h10;
        }
    }

    & .Link {
        @mixin p20;
        @mixin buttonBase;
        @mixin buttonTransitionBehavior color;
        display: inline-flex;
        padding: 0;

        @media (--desktop) {
            @mixin h40;
        }

        &:hover {
            color: var(--primaryHKBaseColor);
        }

        & .LinkLabel {
            display: flex;
            align-items: center;
        }

        & .Icon {
            @mixin fixedRatioSvgIcon 24px, var(--support01HKDarken20Color);
            margin-top: 2px;
        }

        & .Label {
            border-bottom: 2px solid var(--whiteBaseColor);
        }
    }
}

.Search {
    position: relative;
    z-index: 2;
}
