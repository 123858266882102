.Combobox {
    position: relative;
}

.SearchBoxInput {
    @mixin onFocus {
        @mixin focusVisible 0;
        position: relative;
        z-index: 3;
    }
    border: none;
    height: 48px;
    width: 100%;
    padding: 0 16px;

    &::placeholder {
        @mixin p30;
        color: var(--blackBaseColor);
        opacity: 100;
        font-weight: 400;
        font-style: italic;
    }

    &.SearchBoxInput--open {
        position: relative;
        z-index: 3;
    }
}

.Item {
    padding: 12px;
}

.SearchResults {
    @mixin dropdownMenu;
    display: block;
}

.SearchResults--open {
    @mixin dropdownBoxShadow;
}

.Item--highlighted {
    background: var(--blackLighten60Color);
}
