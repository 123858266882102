@define-mixin homeHeroSearchButton $color, $hoverColor, $textColor {
    @mixin button $color, $hoverColor, $textColor;
    font-family: var(--serifFontFamily);
    font-weight: 600;
}

@define-mixin homeHeroSearchButtonMobile $color, $hoverColor, $textColor {
    @mixin homeHeroSearchButton $color, $hoverColor, $textColor;
    @mixin p20;
    padding: 16px 24px;
}

@define-mixin homeHeroSearchButtonTabletAndAbove $color, $hoverColor, $textColor {
    @mixin homeHeroSearchButton $color, $hoverColor, $textColor;
    @mixin h50;
    padding: 16px 80px;
}

.Tabs {
    & .TabList {
        display: flex;
    }

    & .StudySearchTab {
        @mixin homeHeroSearchButtonMobile var(--primaryHKDarken20Color), var(--primaryHKBaseColor),
            var(--whiteBaseColor);

        @mixin onFocus {
            position: relative;
        }

        @media (--mobileOnly) {
            lost-column: 1/2 0 0;
        }

        @media (--tablet) {
            @mixin homeHeroSearchButtonTabletAndAbove var(--primaryHKDarken20Color), var(--primaryHKBaseColor),
                var(--whiteBaseColor);
        }

        &.StudySearchTab--selected {
            background-color: var(--primaryHKBaseColor);
        }
    }

    & .EmployeeSearchTab {
        @mixin homeHeroSearchButtonMobile var(--blackLighten40Color), var(--blackLighten50Color),
            var(--blackLighten10Color);

        @mixin onFocus {
            position: relative;
        }

        @media (--mobileOnly) {
            lost-column: 1/2 0 0;
        }

        @media (--tablet) {
            @mixin homeHeroSearchButtonTabletAndAbove var(--blackLighten40Color), var(--blackLighten50Color),
                var(--blackLighten10Color);
        }

        &.EmployeeSearchTab--selected {
            background-color: var(--blackLighten50Color);
        }
    }
}
